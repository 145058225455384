import React, { useState, useEffect } from "react";
import "./new.css";
import {
  mint,
  totalSupply,
  maxSupply_,
  getCurrentWalletConnected,
} from "../../utils/interact";

function Mint() {
  const [counter, setCounter] = useState(1);
  const [loading, setLoader] = useState(false);
  const [tsupply, setTotalSupply] = useState(false);
  const [maxsupply, setMaxSupply] = useState(0);

  const Subtract = () => {
    if (counter > 1) {
      setCounter(parseInt(counter) - 1);
    }
  };

  const Add = () => {
    if (counter < 20) {
      setCounter(parseInt(counter) + 1);
    }
  };
  const mintNft = async (mntQnt) => {
    setLoader(true);
    if (mntQnt <= 20) {
      const wallet = await getCurrentWalletConnected();
      console.log(wallet);
      if (wallet !== undefined) {
        await mint(mntQnt, tsupply);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    const getSupply = async () => {
      const totalSupply_ = await totalSupply();
      console.log(totalSupply_);
      if (totalSupply_.success !== false) {
        setTotalSupply(String(totalSupply_.value));
      }
    };
    getSupply();
  }, []);

  useEffect(() => {
    const getSupply = async () => {
      const maxSupply = await maxSupply_();
      console.log(maxSupply);
      if (maxSupply.success !== false) {
        setMaxSupply(String(maxSupply.value));
      }
    };
    getSupply();
  }, []);

  return (
    <>
      <div className="minter_container" id="mint" style={{ marginTop: "5rem" }}>
        <h2 style={{ textAlign: "center", color: "#E7E6E8", fontSize: "35px" }}>
          Mint NFTs
        </h2>

        <div className="mint_section" style={{ marginTop: "3rem" }}>
          <div className="mint_value" style={{ color: "#E7E6E8" }}>
            <h3 style={{ color: "#E7E6E8", fontSize: "17px" }}>
              Mint 20 NFTs Per Wallet Max
            </h3>
            <p style={{ color: "#E7E6E8" }}>
              {tsupply} Minted out of {maxsupply} Collection
            </p>
            <h5 style={{ color: "#E7E6E8", fontSize: "14px" }}>
              {" "}
              NFT Price = 0.75 ETC + Gas Fee
            </h5>
          </div>
          <br />
          <div className="mint_box">
            <div className="mint" style={{ width: "40%" }}>
              <button
                onClick={() => Subtract()}
                style={{ border: "1px solid transparent", cursor: "pointer" }}
              >
                {" "}
                -{" "}
              </button>
              <span> {counter} </span>
              <button
                onClick={() => Add()}
                style={{ border: "1px solid transparent", cursor: "pointer" }}
              >
                {" "}
                +{" "}
              </button>
            </div>

            <button
              className="circle_button"
              style={{
                cursor: "pointer",
              }}
              onClick={() => setCounter(20)}
            >
              20
            </button>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="cs-btn_group"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading === false ? (
                <div
                  //   className="cs-btn cs-btn_filed cs-accent_btn"
                  className="banner-btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => mintNft(counter)}
                >
                  <span>Mint Now</span>
                </div>
              ) : (
                <>Processing..............</>
              )}
            </div>
          </div>
          <h5
            style={{
              textAlign: "center",
              fontSize: "12px",
              marginTop: "2rem",
            }}
          >
            Please use Chrome/Firefox with metamask extension for PC or metamask
            app for mobile
          </h5>
          <h3 className="contract">
            <a
              style={{
                textAlign: "center",
                fontSize: "14px",
                cursor: "pointer",
              }}
              href="https://etc.blockscout.com/address/0xf55D1335686387881c1a9c910B71884d219B1d0e"
            >
              View Contract
            </a>
          </h3>
        </div>
      </div>

      <section className="design-ways-area" id="roadmap">
        <div className="container">
          <h2 className="title">Pepe Legend Roadmap </h2>
          <br />
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <div className="design-ways-item">
                <div className="content">
                  <h3
                    className="title"
                    style={{
                      fontSize: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Phase 1: LP Incremental Burn Goal / Hebeswap Whitelist
                  </h3>
                  <p>Achieving Liquidity and Community Harmony</p>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    1. LP Incremental Burn Goal:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Participate in our Liquidity Provider (LP) program and
                      watch as incremental burn goals enhance scarcity.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Join the movement to reduce the circulating supply and
                      witness the positive impact on PepeCoin Classic's value.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    2. Whitelist on Hebeswap:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Access PepeCoin Classic easily by securing a whitelist
                      spot on Hebeswap.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Dive into decentralized exchange transactions and elevate
                      liquidity for an enriched trading experience.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    3. Community Engagement:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Participate in community-driven initiatives to earn
                      rewards and boost LP program engagement.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Stay informed through transparent communication channels
                      as we celebrate milestones together.
                    </li>
                  </li>
                </div>
              </div>
            </div>
            <div className="col-xl-12 ">
              <div className="design-ways-item">
                <div className="content">
                  <h3
                    className="title"
                    style={{
                      fontSize: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Phase 2: Development of 2D Collection of Games
                  </h3>
                  <p>Gaming Galore with PepeCoin Classic Rewards</p>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    1. 2D Game Development:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Immerse yourself in captivating 2D games featuring
                      PepeCoin Classic characters
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Earn PepeCoin Classic in-game for exciting challenges and
                      unlock exclusive NFTs.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    2. In-Game Rewards:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Spend your PepeCoin Classic in-game for purchases and
                      unique rewards.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Compete in community tournaments for a chance to win rare
                      NFTs and substantial PepeCoin Classic rewards.
                    </li>
                  </li>
                </div>
              </div>
            </div>
            <div className="col-xl-12 ">
              <div className="design-ways-item">
                <div className="content">
                  <h3
                    className="title"
                    style={{
                      fontSize: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Phase 3: 3D Game with Pepe, Grey, Zuki Metaverse
                  </h3>
                  <p>Step into the 3D World of Pepe Legends</p>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    1. Metaverse Development:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Explore the metaverse with Pepe, Grey, and Zuki in a fully
                      immersive 3D environment.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Utilize PepeCoin Classic seamlessly for transactions and
                      interactions within the metaverse.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    2. Virtual Economy:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Build your virtual empire using PepeCoin Classic as the
                      primary currency.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Purchase virtual assets, land, and unique items to enhance
                      your metaverse experience.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    3. Interactive Events:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Immerse yourself in special events within the metaverse
                      for exclusive NFTs and unique rewards.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Engage in activities designed to showcase the limitless
                      possibilities of PepeCoin Classic in the virtual world.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    4. Community Governance in Metaverse:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Have a voice in the development and direction of PepeCoin
                      Classic within the metaverse
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Participate in decentralized governance systems that
                      empower the community to shape the virtual landscape.
                    </li>
                  </li>
                </div>
              </div>
            </div>
            <div className="col-xl-12 ">
              <div className="design-ways-item">
                <div className="content">
                  <h3
                    className="title"
                    style={{
                      fontSize: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Overall Considerations:
                  </h3>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    Continuous Community Involvement:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Engage with the community regularly and provide feedback
                      to influence the roadmap.
                    </li>
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Become a part of the decision-making process for a truly
                      community-driven experience.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    Partnerships and Collaborations:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Witness the expansion of Pepe Legends through strategic
                      partnerships with NFT projects, gaming platforms, and
                      blockchain initiatives.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    Security and Transparency:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Experience a secure ecosystem with transparent
                      communication channels keeping you informed about
                      developments and challenges.
                    </li>
                  </li>
                  <br />
                  <li
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    Marketing and Awareness:
                    <br />
                    <br />
                    <li
                      style={{
                        fontSize: "12px",
                        listStyle: "circle",
                      }}
                    >
                      Stay tuned for a comprehensive marketing strategy,
                      leveraging social media, influencers, and community
                      ambassadors to spread the word about Pepe Legends and the
                      vibrant PepeCoin Classic ecosystem.
                    </li>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mint;
