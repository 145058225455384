import ABI from "../ABI.json";
import Web3 from "web3";
import { getEvents } from "./event.js";

const { ethereum } = window;
const web3 = new Web3(ethereum);
const contractAddress = "0xf55D1335686387881c1a9c910B71884d219B1d0e";
const ABI_VALUE = ABI;
const contract = new web3.eth.Contract(ABI_VALUE, contractAddress);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const status = await checkChainID();
      if (status === true) {
        const obj = {
          res: addressArray[0],
          status: true,
        };
        return obj;
      } else {
        const obj = {
          res: "Please Connect to ETC",
          status: false,
        };
        return obj;
      }
    } catch (err) {
      return {
        res: "User Rejected Request",
        false: false,
      };
    }
  }
};

const checkChainID = async () => {
  const chainid = await web3.eth.net.getId();
  console.log(chainid);
  if (chainid === 1n) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      const status = await checkChainID();
      if (status === true) {
        return {
          res: addressArray[0],
          status: true,
        };
      } else {
        return {
          res: "Please Connect to Goerli",
          status: false,
        };
      }
    } catch (err) {
      return {
        res: "",
        status: false,
      };
    }
  }
};

function generateUniqueRandomNumbers(existingArray, count, min, max) {
  const result = [];

  while (result.length < count) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    if (
      !existingArray.includes(String(randomNumber)) &&
      !result.includes(String(randomNumber)) &&
      !result.includes(randomNumber)
    ) {
      result.push(String(randomNumber));
    }
  }

  return result;
}

export const mint = async (minQnt, tsupply) => {
  if (window.ethereum) {
    const status = await checkChainID();
    if (status === true) {
      try {
        const accounts = await web3.eth.getAccounts();
        let ETH_COST = 750000000000000000;
        if (Number(tsupply) <= 5555) {
          let totalCostWei = String(ETH_COST * minQnt);
          const minted = await getEvents();
          const idArray = minted.map((obj) => obj.tokenId);
          const generatedNumbers = generateUniqueRandomNumbers(
            idArray,
            minQnt,
            1,
            5555
          );
          // console.log(generatedNumbers, accounts[0], totalCostWei);
          const response = await contract.methods.mint(generatedNumbers).send({
            from: accounts[0],
            value: totalCostWei,
            type: "0x1",
          });
          console.log(response);
          return {
            success: true,
            response,
          };
        }
      } catch (error) {
        console.log(error);
        return {
          success: false,
          response: "😥 Mint gone wrong: " + error.message,
        };
      }
    }
  }
};

export const TransferNFTCall = async (to, token) => {
  const accounts = await web3.eth.getAccounts();
  try {
    console.log(accounts[0], to, String(token));
    const response = await contract.methods
      .transferFrom(accounts[0], to, String(token))
      .send({
        from: accounts[0],
        type: "0x1",
      });
    console.log(response);
    return {
      success: true,
      status: "🙌 NFT Successfully Transfered",
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const Withdraw_ = async (to, token) => {
  const accounts = await web3.eth.getAccounts();
  try {
    console.log(accounts[0], to, String(token));
    const response = await contract.methods.withdraw().send({
      from: accounts[0],
      type: "0x1",
    });
    console.log(response);
    return {
      success: true,
      status: "Withdrawal Successful",
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };
  }
};

export const walletOfOwner = async () => {
  if (window.ethereum) {
    const accounts = await web3.eth.getAccounts();

    try {
      const token = await contract.methods.walletOfOwner(accounts[0]).call();
      return token;
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};

export const OwnerOf = async (token) => {
  if (window.ethereum) {
    // const accounts = await web3.eth.getAccounts();

    try {
      const address = await contract.methods.ownerOf(token).call();
      return address;
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};

export const totalSupply = async () => {
  if (window.ethereum) {
    try {
      const token = await contract.methods.totalSupply().call();
      return {
        success: true,
        value: token,
      };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};

export const maxSupply_ = async () => {
  if (window.ethereum) {
    try {
      const token = await contract.methods.maxSupply().call();
      return {
        success: true,
        value: token,
      };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  } else {
    return {
      success: false,
      status: "😥 Something went wrong: ",
    };
  }
};
