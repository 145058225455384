import ABI from "../ABI.json";
import Web3 from "web3";
const metadata = require("../metadata.json");
const web3 = new Web3("https://etc.rivet.link");

// Replace with your contract address and ABI (Application Binary Interface)
const contractAddress = "0xf55D1335686387881c1a9c910B71884d219B1d0e";

// Create a contract instance
const contract = new web3.eth.Contract(ABI, contractAddress);

// Get past events (old events)
export async function getEvents() {
  console.log(1);
  const events = await contract.getPastEvents("Transfer", {
    fromBlock: "earliest", // Start block
    toBlock: "latest", // End block
  });
  const mintedArray = [];
  // eslint-disable-next-line array-callback-return
  events.map((item) => {
    if (
      item.returnValues.from === "0x0000000000000000000000000000000000000000"
    ) {
      mintedArray.push({
        to: item.returnValues.to,
        tokenId: Number(item.returnValues.tokenId),
        attribute: metadata[Number(item.returnValues.tokenId) - 1],
      });
    }
  });
  return mintedArray;
}
