import React from "react";

function About() {
  return (
    <div className="banner-bg">
      <div className="banner-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8">
              <div
                className="banner-content"
                style={{
                  paddingTop: "61px",
                }}
              >
                <h2 className="title">
                  Connect Wallet and <br /> Mint <span>Pepe Legend</span>{" "}
                  collection
                </h2>
                <a href="/#mint" className="banner-btn">
                  MINT Pepe Legend NFT<i className="fi-sr-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <img
                src="assets/img/33.png"
                alt=""
                style={{
                  boxShadow:
                    "0 10px 21px 0 rgb(249 236 236 / 20%), 0 18px 45px 0 rgba(0, 0, 0, 0.19)",
                  width: "100%",
                  borderRadius: "20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
