import React from "react";

function About() {
  return (
    <>
      <div className="latest-news-area" id="about">
        <section className="week-features-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title mb-35">
                  <h2 className="title">
                    About Pepe Legend{" "}
                    <img src="assets/img/icons/title_icon02.png" alt="" />
                  </h2>
                  <br />
                  <p>
                    What is life like when you walk the path of a legend.. What
                    if I told you that's what life is all about? Each one of us,
                    everyday walk the path that we think best suits ourself, but
                    do we ever stop to think 🤔… "Is the path im following that
                    of my own Legend?" PepeLegends illustrates in essence what a
                    Legend is, the ability to do what you want & when you want
                    with your Pepe..
                  </p>
                  <p>
                    The team is deploying these NFTs as a way to fund
                    PepeClassic future developments including a game!! LP
                    whitelist on Hebe & future giveaways & community
                    incentives.. Let's build a positive pepe place where we can
                    lean on each other & build each other up ETC {">"} ETH PEPE{" "}
                    {">"}
                    Fork🐸
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="latest-news-areaa">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb-45">
                <h2 className="title">
                  Connect Wallet and Mint Pepe Legend collection{" "}
                  <img src="assets/img/icons/title_icon03.png" alt="" />
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="sell-nfts-item">
                <img
                  src="assets/img/icons/nfts_01.png"
                  alt=""
                  className="icon"
                />
                <span className="step-count">go step 1</span>
                <h5 className="title">Connect Wallet</h5>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="sell-nfts-item">
                <img
                  src="assets/img/icons/nfts_02.png"
                  alt=""
                  className="icon"
                />
                <span className="step-count">go step 2</span>
                <h5 className="title">Select Your Quantity</h5>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="sell-nfts-item">
                <img
                  src="assets/img/icons/nfts_03.png"
                  alt=""
                  className="icon"
                />
                <span className="step-count">go step 3</span>
                <h5 className="title">Transaction Confirm</h5>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="sell-nfts-item">
                <img
                  src="assets/img/icons/nfts_04.png"
                  alt=""
                  className="icon"
                />
                <span className="step-count">go step 4</span>
                <h5 className="title">Receive Your NFTs</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
