import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connectWallet } from "../utils/interact";

function Header() {
  const [connectionSatatus, setConnectionStatus] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [errorMsg, setErrorMssg] = useState("");

  const connectWalletPressed = async () => {
    const response = await connectWallet();
    console.log(response);
    if (response?.status) {
      // window.location.reload();
      setConnectionStatus(true);
      setWallet(response?.res);
    } else {
      setConnectionStatus(false);
      setErrorMssg(response?.res);
    }
  };

  return (
    <header>
      <div className="menu-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div
                    className="logo"
                    style={{
                      padding: "5px",
                    }}
                  >
                    <Link to="/">
                      <img
                        src="assets/logo.webp"
                        alt=""
                        style={{
                          borderRadius: "360px",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <a href="/#about">About</a>
                      </li>
                      <li>
                        <a href="/#roadmap">Roadmap</a>
                      </li>
                      <li>
                        <a href="/#mint">Mint</a>
                      </li>
                      <li>
                        <Link to="/mynfts">My NFTs</Link>
                      </li>
                      <li>
                        <Link to="/gallery">PepeLegends Gallery</Link>
                      </li>
                      <li>
                        <a href="https://twitter.com/PepeLegendsETC">
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/PepeCoinClassic">
                          <span className="fab fa-telegram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      <li className="header-btn">
                        <a
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={connectWalletPressed}
                          className="btn"
                        >
                          {/* Wallet Connect */}
                          {connectionSatatus === false ? (
                            <>Connect Wallet</>
                          ) : (
                            <>
                              <Link to="/mynfts">
                                {String(walletAddress).substring(0, 6) +
                                  "..." +
                                  String(walletAddress).substring(38)}
                              </Link>
                            </>
                          )}
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* {walletAddress ===
                    "0xdc4956fB805b3DcBef4Cf09c8d74970AbeBd48d3" && (
                    <div className="header-action d-none d-md-block">
                      <ul>
                        <li className="header-btn">
                          <a
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => withdrawal()}
                            className="btn"
                          >
                            Withdraw
                          </a>
                        </li>
                      </ul>
                    </div>
                  )} */}
                </nav>
              </div>
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <a href="index-2.html">
                      <img src="assets/img/logo/logo.png" alt="" />
                    </a>
                  </div>
                  <div className="menu-outer">
                    <li
                      className="header-btn"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <a
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={connectWalletPressed}
                        className="btn"
                      >
                        {/* Wallet Connect */}
                        {connectionSatatus === false ? (
                          <>Connect Wallet</>
                        ) : (
                          <>
                            <Link to="/mynfts">
                              {String(walletAddress).substring(0, 6) +
                                "..." +
                                String(walletAddress).substring(38)}
                            </Link>
                          </>
                        )}
                      </a>
                    </li>
                  </div>
                  <div className="social-links">
                    <ul className="clearfix">
                      <li>
                        <a href="https://twitter.com/PepeLegendsETC">
                          <span className="fab fa-twitter"></span>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/PepeCoinClassic">
                          <span className="fab fa-telegram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
