import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import Gallery from "./pages/Gallery";
import NFTs from "./pages/NFTs";
import Header from "./components/Header";
import Withdraw from "./pages/Withdraw";
// import { Withdraw } from "./utils/interact";

const App = () => {
  return (
    <>
      <div className="main-content">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mynfts" element={<Gallery />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/gallery" element={<NFTs />} />
            {/* <Route path="/minted" element={<Minted />} /> */}
          </Routes>
        </main>
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default App;
