import React from "react";
import { Withdraw_ } from "../utils/interact";

function Withdraw() {
  const withdrawal = async () => {
    const response = await Withdraw_();
    console.log(response);
  };

  return (
    <div className="header-action d-none d-md-block">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ul>
        <li className="header-btn">
          <a
            style={{
              cursor: "pointer",
            }}
            onClick={() => withdrawal()}
            className="btn"
          >
            Withdraw
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Withdraw;
