import React from "react";
import Top from "../components/home/Top";
import About from "../components/home/About";
import Mint from "../components/home/Mint";

function Home() {
  return (
    <>
      <Top />
      <About />
      <Mint />
    </>
  );
}

export default Home;
