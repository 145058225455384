import React from "react";

function Footer() {
  return (
    <footer>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="copyright-text">
                <p>
                  All rights reserved © 2022 by <a href="#">ThemeBeyond</a>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="copyright-link-list">
                <li>
                  <a href="login-register.html">Privacy Policy</a>
                </li>
                <li>
                  <a href="login-register.html">Terms And Condition</a>
                </li>
                <li>
                  <a href="login-register.html">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
