import React, { useState, useEffect } from "react";
import metadata from "../metadata.json";
import rarity from "../rarity.json";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PaginatedTable = ({ itemsPerPage, data, totalPages }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {currentItems.map((item, i) => (
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
          <div className="top-collection-item">
            <div className="collection-item-thumb">
              <span>
                <LazyLoadImage
                  src={item.file_url}
                  placeholderSrc={"assets/img/placeholder.jpg"}
                  alt="bird"
                />
              </span>
            </div>
            <div className="collection-item-content">
              <h5 className="title">
                <span
                  style={{
                    color: "#fff",
                  }}
                >
                  Pepe Legend #{item.edition}
                </span>{" "}
              </h5>
              <span
                style={{
                  color: "#e039fd",
                  padding: "21px",
                  fontSize: "13px",
                }}
              >
                Rarity {rarity[item.edition - 1].ranking}
              </span>
            </div>
          </div>
        </div>
      ))}

      <div>
        <div>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

function NFTs() {
  const [filter, setFilteredData] = useState(metadata);
  const [attributes, setAttribute] = useState({
    Backgrounds: [
      {
        trait_type: "Backgrounds",
        value: "Red",
      },
      {
        trait_type: "Backgrounds",
        value: "Green4",
      },
      {
        trait_type: "Backgrounds",
        value: "Green2",
      },
      {
        trait_type: "Backgrounds",
        value: "Green1",
      },
      {
        trait_type: "Backgrounds",
        value: "Wormhole",
      },
      {
        trait_type: "Backgrounds",
        value: "Blue",
      },
      {
        trait_type: "Backgrounds",
        value: "Forest",
      },
      {
        trait_type: "Backgrounds",
        value: "ETC",
      },
      {
        trait_type: "Backgrounds",
        value: "Space2",
      },
      {
        trait_type: "Backgrounds",
        value: "PEPECITY",
      },
      {
        trait_type: "Backgrounds",
        value: "Green3",
      },
      {
        trait_type: "Backgrounds",
        value: "Moon",
      },
      {
        trait_type: "Backgrounds",
        value: "Cyan",
      },
      {
        trait_type: "Backgrounds",
        value: "Bullish",
      },
      {
        trait_type: "Backgrounds",
        value: "Space1",
      },
      {
        trait_type: "Backgrounds",
        value: "Volcano",
      },
      {
        trait_type: "Backgrounds",
        value: "Space_B",
      },
      {
        trait_type: "Backgrounds",
        value: "ETC_Background",
      },
      {
        trait_type: "Backgrounds",
        value: "Moon_B",
      },
      {
        trait_type: "Backgrounds",
        value: "Pepe_City",
      },
      {
        trait_type: "Backgrounds",
        value: "WormHole_B",
      },
      {
        trait_type: "Backgrounds",
        value: "Volcano_B",
      },
      {
        trait_type: "Backgrounds",
        value: "ETC_B",
      },
      {
        trait_type: "Backgrounds",
        value: "Blue_B",
      },
    ],
    Auras: [
      {
        trait_type: "Auras",
        value: "Thunder_Arua",
      },
      {
        trait_type: "Auras",
        value: "Base_Aura",
      },
      {
        trait_type: "Auras",
        value: "Super_Saiyan",
      },
      {
        trait_type: "Auras",
        value: "Flame_Aura",
      },
      {
        trait_type: "Auras",
        value: "Ultra_Instinct",
      },
      {
        trait_type: "Auras",
        value: "Thunder_Super_Saiyan",
      },
      {
        trait_type: "Auras",
        value: "SSY",
      },
      {
        trait_type: "Auras",
        value: "Thunder_Aura",
      },
      {
        trait_type: "Auras",
        value: "SSY_Thunder_Aura",
      },
    ],
    BaseSkin: [
      {
        trait_type: "BaseSkin",
        value: "Skin",
      },
      {
        trait_type: "BaseSkin",
        value: "Space",
      },
      {
        trait_type: "BaseSkin",
        value: "Gold",
      },
      {
        trait_type: "BaseSkin",
        value: "Space_Gold",
      },
    ],
    Tshirts: [
      {
        trait_type: "Hands and Tshirts",
        value: "Thinking",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "LoveETC",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "White_Tshirt",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Widearms",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Widearms_BullT",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "ETHRoast",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Jacket",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Moon_Tshirt",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Basketball_TT",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Construction_worker",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Tatoos",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Business_Suit",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Smartass",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "PEPEskull_T",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Football_Tshirt",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "RefreeTshirt",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "WideArms_69",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Widearms_ETC",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Goku_Outfit",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "None",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Comet_Jacket",
      },
      {
        trait_type: "Hands and Tshirts",
        value: "Suit",
      },
    ],
    Mouth: [
      {
        trait_type: "Mouth",
        value: "Neutral",
      },
      {
        trait_type: "Mouth",
        value: "Angry",
      },
      {
        trait_type: "Mouth",
        value: "Happy",
      },
      {
        trait_type: "Mouth",
        value: "Smirk",
      },
      {
        trait_type: "Mouth",
        value: "Cigar",
      },
      {
        trait_type: "Mouth",
        value: "Moustache",
      },
      {
        trait_type: "Mouth",
        value: "Sad",
      },
      {
        trait_type: "Mouth",
        value: "Golden_Smirk",
      },
      {
        trait_type: "Mouth",
        value: "Sad2",
      },
      {
        trait_type: "Mouth",
        value: "Blood",
      },
      {
        trait_type: "Mouth",
        value: "Fly_Catching",
      },
      {
        trait_type: "Mouth",
        value: "Smirk_20",
      },
      {
        trait_type: "Mouth",
        value: "Fly_Hunt",
      },
      {
        trait_type: "Mouth",
        value: "Cigar_Mouth",
      },
      {
        trait_type: "Mouth",
        value: "Gold_Smirk",
      },
    ],
    Eyes: [
      {
        trait_type: "Eyes",
        value: "Blink",
      },
      {
        trait_type: "Eyes",
        value: "Red_Eyes",
      },
      {
        trait_type: "Eyes",
        value: "Wink",
      },
      {
        trait_type: "Eyes",
        value: "Crying",
      },
      {
        trait_type: "Eyes",
        value: "ETC_Sharingan",
      },
      {
        trait_type: "Eyes",
        value: "Lightning_eyes",
      },
      {
        trait_type: "Eyes",
        value: "Glasses",
      },
      {
        trait_type: "Eyes",
        value: "Fire_eyes",
      },
      {
        trait_type: "Eyes",
        value: "Laser_Eyes",
      },
      {
        trait_type: "Eyes",
        value: "Zoro_Eyes",
      },
      {
        trait_type: "Eyes",
        value: "Zoro_eyes",
      },
      {
        trait_type: "Eyes",
        value: "Fire_Eyes",
      },
      {
        trait_type: "Eyes",
        value: "ETC_sharingan",
      },
      {
        trait_type: "Eyes",
        value: "Lightning_Eyes",
      },
    ],
    Headware: [
      {
        trait_type: "Headware",
        value: "astronaut",
      },
      {
        trait_type: "Headware",
        value: "Red_Horns",
      },
      {
        trait_type: "Headware",
        value: "StrawHat",
      },
      {
        trait_type: "Headware",
        value: "Non",
      },
      {
        trait_type: "Headware",
        value: "Red_Hat",
      },
      {
        trait_type: "Headware",
        value: "Black_Horns",
      },
      {
        trait_type: "Headware",
        value: "Degree_Hat",
      },
      {
        trait_type: "Headware",
        value: "Angel",
      },
      {
        trait_type: "Headware",
        value: "NarutoBand",
      },
      {
        trait_type: "Headware",
        value: "Angel_Naruto_headband",
      },
      {
        trait_type: "Headware",
        value: "Strawhat",
      },
      {
        trait_type: "Headware",
        value: "Graduate_Hat",
      },
      {
        trait_type: "Headware",
        value: "Astronaut_Helmet",
      },
      {
        trait_type: "Headware",
        value: "Straw_Hat",
      },
    ],
    Signature: [
      {
        trait_type: "Signature",
        value: "Signature",
      },
    ],
  });

  const handleSearch = (query) => {
    if (query === " " || query === "") {
      setFilteredData(metadata);
    } else {
      const filtered = metadata.filter(
        (item) => item.edition === Number(query)
      );
      setFilteredData(filtered);
    }
  };

  const handleRarity = (query) => {
    if (query === " " || query === "") {
      setFilteredData(metadata);
    } else {
      const filtered = metadata.filter(
        (item) => rarity[item.edition - 1].ranking === Number(query)
      );
      setFilteredData(filtered);
    }
  };

  const filterData = (key, value) => {
    console.log(key, value);
    const filtered = metadata.filter((item) =>
      item.attributes.some((obj) => obj.value === value)
    );
    setFilteredData(filtered);
  };

  return (
    <section className="explore-products-area">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <div className="row mb-35">
          <div className="col-md-12 col-sm-12">
            <div className="section-title">
              <h2 className="title">
                PepeLegends NFT{" "}
                <img src="assets/img/icons/title_icon01.png" alt="" />
              </h2>
              <br />
              <div className="row">
                <div className="col-sm-4 col-md-4">
                  <input
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search Base Token ID"
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  />
                </div>
                <div className="col-sm-4 col-md-4">
                  <input
                    onChange={(e) => handleRarity(e.target.value)}
                    placeholder="Search Base on Rarity"
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                  />
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Backgrounds", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Backgrounds
                    </option>
                    {attributes.Backgrounds.map((item, i) => {
                      return <option>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Auras", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Auras
                    </option>
                    {attributes.Auras.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("BaseSkin", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select BaseSkin
                    </option>
                    {attributes.BaseSkin.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Mouth", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Mouth
                    </option>
                    {attributes.Mouth.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Tshirts", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Hands and Tshirts
                    </option>
                    {attributes.Tshirts.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Eyes", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Eyes
                    </option>
                    {attributes.Eyes.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Headware", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Headware
                    </option>
                    {attributes.Headware.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
                <div className="col-sm-4 col-md-4">
                  <select
                    style={{
                      width: "100%",
                      marginBottom: "5px",
                    }}
                    onChange={(e) => filterData("Signature", e.target.value)}
                  >
                    <option defaultValue={null} selected>
                      Select Signature
                    </option>
                    {attributes.Signature.map((item, i) => {
                      return <option value={item.value}>{item.value}</option>;
                    })}
                    {}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <PaginatedTable
            itemsPerPage={50}
            data={filter}
            totalPages={Math.ceil(metadata.length / 50)}
          />
        </div>
      </div>
    </section>
  );
}

export default NFTs;
