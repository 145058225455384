import React, { useEffect, useState } from "react";
import { walletOfOwner, TransferNFTCall } from "../utils/interact";
import metadata from "../metadata.json";

const Gallery = () => {
  const [tokenID, setTokenID] = useState([]);
  const [address, setAddress] = useState("");
  const [input, setInput] = useState(false);

  useEffect(() => {
    const getNFT = async () => {
      const tokenID_ = await walletOfOwner();
      console.log(tokenID_);
      if (tokenID_.success === false) {
      } else {
        setTokenID(tokenID_);
      }
    };
    getNFT();
  }, []);
  const sendToken = async (id) => {
    await TransferNFTCall(address, id);
  };
  return (
    <section className="explore-products-area">
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <div className="row mb-35">
          <div className="col-md-7 col-sm-8">
            <div className="section-title">
              <h2 className="title">
                My NFT <img src="assets/img/icons/title_icon01.png" alt="" />
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {tokenID.length !== 0 ? (
            <>
              {" "}
              {tokenID.map((item, i) => (
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
                  <div className="top-collection-item">
                    <div className="collection-item-thumb">
                      <a>
                        <img src={metadata[Number(item) - 1].file_url} alt="" />
                      </a>
                    </div>
                    <div className="collection-item-content">
                      <h5 className="title">
                        <a>Pepe Legend #{String(item)}</a>{" "}
                      </h5>
                    </div>
                    {input && (
                      <input
                        placeholder="Enter Wallet address"
                        style={{
                          width: "100%",
                          border: "none",
                          margin: "30px auto",
                          background: "inherit",
                          padding: "10px",
                          color: "#fff",
                        }}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    )}

                    <button
                      style={{
                        width: "100%",
                        border: "none",
                        background: "inherit",
                        padding: "10px",
                      }}
                      classNameName="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn"
                      data-modal="connect_wallet"
                      onClick={() => {
                        if (input === false) {
                          setInput(true);
                        } else {
                          if (address === "") {
                            setInput(false);
                          } else {
                            sendToken(item);
                          }
                        }
                      }}
                    >
                      Transfer NFT
                    </button>
                  </div>
                </div>
              ))}{" "}
            </>
          ) : (
            <> You Have not Minted any NFT Yet</>
          )}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
